.mainContainerStyle {
  width: 100%;
  /* max-height: 470px; */
  /* height: 470px; */
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 10;
}
.mainImgStyle {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.subContainerStyle {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translateX(-50%);
}
.imgContainerStyle {
  width: 38px;
  height: 38px;
}
.imgStyle {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: all 1s;
  cursor: pointer;
}
.TitletextStyle {
  font-family: "Colina_Rounded";
  font-style: normal;
  font-size: 65px;
  line-height: 112.6%;
  text-align: center;
  color: #ffffff;
  white-space: nowrap;
}
.animateDivStyle {
  height: 0px;
  width: 100%;
  animation: closeanim 1s ease-in-out;
  background-color: #222222;
  transform: height 1s;
}
.animateOpenDivStyle {
  height: auto;
  width: 100%;
  animation: openanim 1s ease-in-out;
  background-color: #222222;
  transform: height 1s;
}
@keyframes openanim {
  0% {
    opacity: 0;
    height: 768px;
    transform: height 1s;
    transform: translateY(-50%);
  }

  100% {
    opacity: 1;
    height: 768px;
    transform: height 1s;
    transform: translateY(0%);
  }
}
@keyframes closeanim {
  0% {
    height: 768px;
    transform: height (0%);
    transform: translateY(0%);
  }
  100% {
    height: 0px;
    transform: height (-100%);
    transform: translateY(-342px);
  }
}

.detailsSubContainerStyle {
  display: grid;
  grid-template-columns: auto auto;
  padding: 111px 124px 111px 124px;
  height: 100%;
  overflow: hidden;
  width: 100%;
  row-gap: 85px;
  column-gap: 192px;
}
.containerStyle {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  max-width: 700px;
}
.textContainerStyle {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.detaileTitleTextStyle {
  font-family: "Colina_Rounded";
  font-style: normal;
  font-size: 37px;
  line-height: 112.6%;
  color: #ffffff;
  white-space: nowrap;
}
.detaileDescTextStyle {
  font-family: "Barlow-light";
  font-size: 21.537px;
  line-height: 30px;
  color: #ffffff;
  width: 100%;
}
.otherCategoryDetailStyle {
  height: 228px;
  width: 100%;
  background-color: #222222;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* padding: 94px; */
  opacity: 1;
  animation: otherCategoryAnimation 1s;
  gap: 32px;
}
.otherCategoryDetailStyle2 {
  /* display: none; */
  overflow: hidden;
  height: 0px;
  background-color: #222222;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* padding: 94px; */
  animation: closeOtherCategoryAnimation 1s;
}
@keyframes otherCategoryAnimation {
  0% {
    opacity: 0;
    transform: height 1s;
    height: 228px;
    transform: translateY(-50%);
  }
  100% {
    opacity: 1;
    transform: height 1s;
    height: 228px;
    transform: translateY(0%);
  }
}
/* @keyframes closeOtherCategoryAnimatio {
  0% {
    opacity: 1;
    height: 228px;
    transform: height (0%);
    transform: translateY(0%);
    padding: 94px;
    gap: 32px;
  }
  100% {
    opacity: 0;
    height: 0px;
    transform: height (-100%);
    transform: translateY(-768px);
    gap: 0px;
    padding: 94px;
  }
} */
@keyframes closeOtherCategoryAnimation {
  0% {
    /* padding: 54px; */
    height: 228px;
    gap: 32px;
    transform: height (0%);
  }
  100% {
    /* padding: 0px; */
    height: 0px;
    gap: 0px;
    transform: height (-100%);
  }
}
.bgStyle .imgStyle {
  transform: rotate(180deg);
  transition: all 1s;
}
.detaileDescTextBlockStyle {
  max-width: 80%;
  width: 100%;
  margin-inline: auto;
  text-align: center;
}
@media screen and (min-width: 1441px) {
  .mainContainerStyle {
    height: auto;
  }
}
@media screen and (max-width: 1360px) {
  .detailsSubContainerStyle {
    column-gap: 160px;
  }
}
@media screen and (max-width: 1280px) {
  .detailsSubContainerStyle {
    column-gap: 120px;
  }
}
@media screen and (max-width: 1080px) {
  .subContainerStyle {
    gap: 32px;
    top: 40%;
  }
  .TitletextStyle {
    font-size: 54px;
    line-height: 100.6%;
  }
  .imgContainerStyle {
    height: 32px;
    width: 32px;
  }
  .detailsSubContainerStyle {
    padding: 84px 92px;
    gap: 32px;
  }
  .detaileTitleTextStyle {
    font-size: 28px;
    line-height: 100.6%;
  }
  .detaileDescTextStyle {
    font-size: 18.537px;
    line-height: 28px;
  }
  .containerStyle {
    gap: 28px;
  }
  @keyframes openanim {
    0% {
      opacity: 0;
      height: 550px;
      transform: height 1s;
      transform: translateY(-15%);
    }

    100% {
      opacity: 1;
      height: 550px;
      transform: height 1s;
      transform: translateY(0%);
    }
  }
  @keyframes closeanim {
    0% {
      height: 550px;
      transform: height (0%);
      transform: translateY(0%);
    }
    100% {
      height: 0px;
      transform: height (-100%);
      transform: translateY(-100px);
    }
  }
}
@media screen and (max-width: 867px) {
  .mainContainerStyle {
    max-height: 361px;
  }
  .mainImgStyle {
    object-fit: cover;
  }
  .subContainerStyle {
    top: 30%;
    gap: 17px;
  }
  .TitletextStyle {
    font-size: 45.3957px;
  }
  .detailsSubContainerStyle {
    grid-template-columns: auto;
    gap: 85px;
  }
  .detaileTitleTextStyle {
    font-size: 32px;
    width: fit-content;
  }
  .detaileDescTextStyle {
    font-size: 20px;
  }
  .containerStyle {
    width: 100%;
    margin: auto;
  }
}
@media screen and (max-width: 485px) {
  .mainContainerStyle {
    max-height: 169px;
  }
  .TitletextStyle {
    font-size: 30.3957px;
    line-height: 34px;
  }
  .imgContainerStyle {
    width: 18px;
    height: 18px;
  }
  .subContainerStyle {
    top: 30%;
    gap: 5px;
  }
  .detaileDescTextBlockStyle {
    max-width: 90%;
    width: 100%;
  }
  .detailsSubContainerStyle {
    padding: 54px 27px 54px 27px;
  }
  .detaileTitleTextStyle {
    font-size: 30px;
  }
  .detaileDescTextStyle {
    font-size: 18px;
  }
  .containerStyle {
    width: 100%;
    flex-direction: column;
    gap: 11px;
  }
  .otherCategoryDetailStyle {
    align-items: flex-start;
    /* padding: 54px; */
  }
  .detailsSubContainerStyle {
    row-gap: 45px;
    column-gap: 85px;
  }

  .animateDivStyle {
    height: 0px;
    width: 100%;
    animation: closeanim 1s ease-in-out;
    background-color: #222222;
    transform: height 1s;
  }
  .animateOpenDivStyle {
    width: 100%;
    animation: openanim 1s ease-in-out;
    background-color: #222222;
    transform: height 1s;
  }
  @keyframes openanim {
    0% {
      opacity: 0;
      height: 768px;
      transform: height 1s;
      transform: translateY(-15%);
    }

    100% {
      opacity: 1;
      height: 768px;
      transform: height 1s;
      transform: translateY(0%);
    }
  }
  @keyframes closeanim {
    0% {
      height: 768px;
      transform: height (0%);
      transform: translateY(0%);
    }
    100% {
      height: 0px;
      transform: height (-100%);
      transform: translateY(-100px);
    }
  }
}
