@font-face {
  font-family: "Colina_Rounded";
  src: url("../src/assets/fonts/Coluna_Rounded.otf");
  font-weight: 700;
}
@font-face {
  font-family: "Faltige-Regular";
  src: url("../src/assets/fonts/Faltige.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "Barlow-Black";
  src: url("../src/assets/fonts/Barlow-Black.ttf");
  font-weight: 900;
}
@font-face {
  font-family: "Barlow-ExtraBold";
  src: url("../src/assets/fonts/Barlow-ExtraBold.ttf");
  font-weight: 800;
}
@font-face {
  font-family: "Barlow-Bold";
  src: url("../src/assets/fonts/Barlow-Bold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: "Barlow-SemiBold";
  src: url("../src/assets/fonts/Barlow-SemiBold.ttf");
  font-weight: 600;
}
@font-face {
  font-family: "Barlow-Medium";
  src: url("../src/assets/fonts/Barlow-Medium.ttf");
  font-weight: 500;
}
@font-face {
  font-family: "Barlow-Regular";
  src: url("../src/assets/fonts/Barlow-Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "Barlow-light";
  src: url("../src/assets/fonts/Barlow-Light.ttf");
  font-weight: 300;
}
@font-face {
  font-family: "Barlow-Thin";
  src: url("../src/assets/fonts/Barlow-Thin.ttf");
  font-weight: 200;
}
@font-face {
  font-family: "InterTight";
  src: url("./assets/fonts/InterTight.ttf");
}
@font-face {
  font-family: "FSKIM";
  src: url("./assets/fonts/FSKim-Black.woff2");
}
@font-face {
  font-family: "FwrK";
  src: url("./assets/fonts/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7W0Q5nw.woff2");
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}
*::-webkit-scrollbar {
  display: none;
}
#root {
  width: 100%;
  height: 100%;
}
