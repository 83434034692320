.mainContainerStyle {
  width: 100%;
  display: flex;
  padding: 70px 195px;
}
.subContainerStyle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.leftContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 200px;
  order: 1;
  /* margin-top: 25px; */
}
.leftContainerTitleTextStyle {
  font-family: "Colina_Rounded";
  font-style: normal;
  font-size: 24px;
  line-height: 22px;
  color: #ffffff;
}
.optionStyle {
  display: flex;
  flex-direction: row;
  gap: 18px;
}
.imgContainerStyle {
  width: 24px;
  height: 24px;
}
.imgStyle {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.optionTextStyle {
  font-family: "Barlow-Regular";
  font-style: normal;
  font-size: 16px;
  line-height: 150%;
  color: #ffffff;
}
.middleContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 10px;
  order: 2;
}
.beersImgContainerStyle {
  width: 496px;
  height: 158px;
  object-fit: cover;
}
.beersImgStyle {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.logoContainerStyle {
  width: 496px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.logoTextContainerStyle {
  display: flex;
  flex-direction: column;
  width: 318px;
}
.topContentStyle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.contentTextStyle {
  font-family: "Faltige-Regular";
  font-style: normal;
  font-size: 13.6465px;
  line-height: 112.6%;
  text-align: right;
  color: #ffffff;
}
.contentTitleStyle {
  font-family: "Colina_Rounded";
  font-style: normal;
  font-size: 78.2038px;
  line-height: 112.6%;
  text-align: center;
  color: #ffffff;
}
.rightContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 18px;
  order: 3;
}
.rightContainerTextStyle {
  font-family: "Colina_Rounded";
  font-style: normal;
  font-size: 24px;
  line-height: 24px;
  color: #ffffff;
  white-space: nowrap;
}
.socilaMediaContainerStyle {
  display: flex;
  flex-direction: row;
  gap: 16px;
}
.socilaMediaIconStyle {
  width: 40px;
  height: 40px;
}
@media screen and (min-width: 1441px) {
  .mainContainerStyle {
    padding: 70px 90px;
    max-width: 1440px;
    margin: auto;
  }
}
@media screen and (max-width: 1366px) {
  .mainContainerStyle {
    padding: 68px 146px;
  }
}

@media screen and (max-width: 1080px) {
  .mainContainerStyle {
    padding: 40px 72px;
  }
  .beersImgContainerStyle,
  .logoContainerStyle {
    width: 400px;
  }
}
@media screen and (max-width: 867px) {
  .mainContainerStyle {
    padding: 63px 50px 63px 50px;
  }
  .subContainerStyle {
    flex-direction: column;
    padding: 0px;
  }
  .leftContainerStyle {
    order: 2;
    /* padding-left: 48px; */
    width: 222px;
    align-items: flex-start;
    margin: auto;
    max-width: 321px;
    gap: 18px;
  }
  .subContainerStyle {
    gap: 38px;
  }
  .middleContainerStyle {
    order: 1;
  }
  .rightContainerStyle {
    order: 2;
    width: 222px;
    margin: auto;
    text-align: start;
    justify-content: start;
  }

  .logoTextContainerStyle {
    width: 222px;
  }
  .beersImgContainerStyle {
    width: 222px;
    margin: auto;
    height: 110px;
  }
  .logoContainerStyle {
    width: 100%;
  }
  .contentTextStyle {
    font-size: 9.58039px;
  }
  .contentTitleStyle {
    font-size: 54.9022px;
  }
}
@media screen and (max-width: 485px) {
  .mainContainerStyle {
    padding: 0px;
  }
  .subContainerStyle {
    flex-direction: column;
    padding: 20px;
  }
  .leftContainerStyle {
    order: 2;
    /* padding-left: 48px; */
    max-width: 222px;
  }
  .middleContainerStyle {
    order: 1;
    align-items: center;
  }
  .rightContainerStyle {
    order: 3;
    /* padding-left: 48px; */
  }
  .beersImgContainerStyle {
    max-width: 222px;
    height: 110px;
  }
  .logoContainerStyle {
    max-width: 100%;
  }
}
