.maincontainerStyle {
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-columns: 50% 50%;
}
.rightContainerStyle {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.landingImageStyle {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.leftContainerStyle {
  width: 100%;
  height: 100%;
  padding: 0px 164px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* background-color: #1a1a1e; */
  background-color: #121b1a;
}
.leftContainerSbuStyle {
  /* max-width: 390px; */
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
  animation: animation 1s;
}
@keyframes animation {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
.beerWrapperStyle {
  width: 90px;
  height: 90px;
}
.beerIconStyle {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.textContainerStyle {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.titleTextStyle {
  font-family: "FSKIM";
  font-weight: 700;
  font-size: 60px;
  color: #ffffff;
  text-transform: uppercase;
}
.descTextStyle {
  font-family: "FwrK";
  font-weight: 300;
  font-size: 22px;
  line-height: 120.6%;
  color: #ffffff;
  letter-spacing: 0.5px;
}
.btnContainerStyle {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.linkTextSTyle {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  color: #fff;
  display: inline-block;
  font-family: "InterTight";
  font-weight: 400;
  font-size: 20px;
  letter-spacing: 0.5px;
  margin: 0 0 0.875rem;
  mix-blend-mode: difference;
  position: relative;
  text-decoration: none;
}

.linkTextSTyle:after,
.linkTextSTyle:before {
  animation-direction: normal;
  border-bottom: 1px solid #fff;
  bottom: -0.75rem;
  content: "";
  left: 0;
  mix-blend-mode: difference;
  position: absolute;
  -moz-transition: transform 0.6s cubic-bezier(0.77, 0, 0.175, 1);
  -o-transition: transform 0.6s cubic-bezier(0.77, 0, 0.175, 1);
  -webkit-transition: transform 0.6s cubic-bezier(0.77, 0, 0.175, 1);
  transition: transform 0.6s cubic-bezier(0.77, 0, 0.175, 1);
  width: 100%;
}

.linkTextSTyle:before {
  -moz-transform: scaleX(1);
  -o-transform: scaleX(1);
  -ms-transform: scaleX(1);
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  transform-origin: left center;
  -moz-transition-delay: 0.15s;
  -o-transition-delay: 0.15s;
  -webkit-transition-delay: 0.15s;
  transition-delay: 0.15s;
}

.linkTextSTyle:after,
.linkTextSTyle:hover:before {
  -moz-transform: scaleX(0);
  -o-transform: scaleX(0);
  -ms-transform: scaleX(0);
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  transform-origin: right center;
  -moz-transition-delay: 0s;
  -o-transition-delay: 0s;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.linkTextSTyle:hover:after {
  -moz-transform: scaleX(1);
  -o-transform: scaleX(1);
  -ms-transform: scaleX(1);
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  transform-origin: left center;
  -moz-transition-delay: 0.15s;
  -o-transition-delay: 0.15s;
  -webkit-transition-delay: 0.15s;
  transition-delay: 0.15s;
}
@media screen and (max-width: 1280px) {
  .descTextStyle {
    font-family: "InterTight";
    font-weight: 300;
    font-size: 20px;
    line-height: 120.6%;
    color: #ffffff;
    letter-spacing: 0.5px;
  }
  .titleTextStyle {
    font-family: "InterTight";
    font-weight: 700;
    font-size: 50px;
    color: #ffffff;
  }
}
@media screen and (max-width: 1080px) {
  .maincontainerStyle {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
  }
  .leftContainerStyle {
    width: 100%;
    height: 100vh;
    padding: 55px 45px;
  }
  .leftContainerSbuStyle {
    width: 100%;
    height: 100%;
    gap: 0px;
    justify-content: space-between;
  }
  .rightContainerStyle {
    height: 100vh;
  }
  .beerWrapperStyle {
    width: 120px;
    height: 120px;
    border-radius: 20px;
  }
  .beerIconStyle {
    border-radius: 20px;
  }
  .textContainerStyle {
    gap: 30px;
  }
  .titleTextStyle {
    font-size: 50px;
  }
  .descTextStyle {
    font-size: 24px;
  }
  .linkTextSTyle {
    font-size: 24px;
  }
}
@media screen and (max-width: 867px) {
  .leftContainerSbuStyle {
    gap: 16px;
  }
  .textContainerStyle {
    gap: 30px;
  }
}
@media screen and (max-width: 485px) {
  .titleTextStyle {
    font-size: 32px;
  }
  .descTextStyle {
    font-size: 20px;
  }
}
