.containerStyle {
  display: flex;
  width: 100%;
  height: 345px;
  padding: 26px 0px;
  /* align-items: center; */
  justify-content: center;
}
.inner {
  position: relative;
  width: 100%;
  height: 100%;
  align-items: center;
  overflow: hidden;
}
.wrapper {
  position: absolute;
  display: flex;
}

.section {
  display: flex;
  animation: swipe 20s linear infinite var(--reverse) forwards;
}

@keyframes swipe {
  0% {
    transform: translate(0);
  }

  100% {
    transform: translate(-100%);
  }
}
.itemStyle {
  display: flex;
  flex-direction: row;
}
.imageContainerStyle {
  min-width: 255px;
  height: 255px;
}
.imgStyle {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.textStyle {
  font-family: "Colina_Rounded";
  font-style: normal;
  font-size: 48.6348px;
  line-height: 112.6%;
  text-align: center;
  color: #ffffff;
  padding: 0px 10px 0px 10px;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
}
.followTestStyle {
  padding: 20px;
  display: flex;
}
