.mainContainerStyle {
  background-color: #060606;
}
.mapContainerStyle {
  display: flex;
  width: 100%;
  height: 470px;
}
.mapImgStyles {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.categoryContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
@media screen and (max-width: 1080px) {
  .categoryContainerStyle {
    gap: 12px;
  }
}
@media screen and (max-width: 867px) {
  .categoryContainerStyle {
    gap: 10px;
  }
  .mapContainerStyle {
    height: 300px;
  }
}
@media screen and (max-width: 485px) {
  .categoryContainerStyle {
    gap: 8px;
  }
  .mapContainerStyle {
    height: 195px;
  }
}
